<!--
 * @Descripttion:
 * @version: 1.0
 * @Author: Niklaus
 * @Date: 2021-08-22 09:46:38
-->
<template>
  <div>
    <ul class="router-ul">
      <router-link
        tag='li'
        :to="{path:route.path}"
        class="router-li"
        :key="index"
        v-for="(route,index) in routerList"
      >
        {{ route.name }}
      </router-link>
    </ul>
  </div>
</template>

<script>
import tttt from './../router/index'
export default {
  name: 'Index',
  data(){
    return {
      routerList:[]
    }
  },

  beforeMount(){
    this.routerList = tttt.options.routes
  }
}
</script>

<style lang="scss" scoped>
  .router-ul {
    display: flex;
    justify-content: center;
    align-content: center;
    padding: 50px 100px;
    min-height: 100vh;
    background-color: #636e72;
    .router-li {
      font-weight: bold;
      background-color: #74b9ff;
      color: black;
      border-radius: 10px;
      height: 50px;
      line-height: 50px;
      width: 150px;
      text-align: center;
      margin: 15px;
      font-size: 23px;
      list-style: none;
      transition: all 0.5s;
      &:hover {
        color:#55efc4;
        background: #a29bfe;
        cursor: pointer;
        transform: scale(0.9);
      }
    }
  }

</style>